body {
  margin: 0;
  padding: 0;
  font-family: Tahoma, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(./images/complex-background-51trnsp.png); */
  /* background-image: url(./images/background-map.png); */
  background-image: linear-gradient(to right bottom, white 40%, #c2c2c2);
  background-size: cover;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-size: 14px;
  line-height: 1.4;
  font-family: "Arial", sans-serif;
}
